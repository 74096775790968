import React, { useState, useEffect } from 'react';
import OzowStatus from '../components/Ozow'
import Layout from '../components/Layout'

function OzowPayment() {
  const [ozowOpen, setOzowOpen] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  return <Layout noHeader noMoku noFooter>
    <OzowStatus open={ozowOpen} setOpen={setOzowOpen} url={url} successMessage={'Thanks for your payment.'} failMessage={'We have been unable to process your payment. Please try again.'} />
  </Layout>
}

export default OzowPayment